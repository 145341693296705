var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "employee-new-modal" } },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: { title: _vm.title, active: _vm.localShow },
          on: {
            "update:active": function ($event) {
              _vm.localShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "flex items-start flex-col lg:flex-row" },
                [
                  _c("div", { staticClass: "w-full" }, [
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.initial",
                                value: "required|alpha_spaces",
                                expression: "'required|alpha_spaces'",
                                modifiers: { initial: true },
                              },
                            ],
                            staticClass: "w-full mt-0",
                            attrs: {
                              label: "Nome Fantasia",
                              "data-vv-as": "Nome Fantasia",
                              name: "trading_name",
                            },
                            model: {
                              value: _vm.cac.trading_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.cac, "trading_name", $$v)
                              },
                              expression: "cac.trading_name",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("trading_name"),
                                  expression: "errors.has('trading_name')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [_vm._v(_vm._s(_vm.errors.first("trading_name")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.initial",
                                value: "required|alpha_spaces",
                                expression: "'required|alpha_spaces'",
                                modifiers: { initial: true },
                              },
                            ],
                            staticClass: "w-full mt-0",
                            attrs: {
                              label: "Razão Social",
                              "data-vv-as": "Razão Social",
                              name: "corporate_name",
                            },
                            model: {
                              value: _vm.cac.corporate_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.cac, "corporate_name", $$v)
                              },
                              expression: "cac.corporate_name",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("corporate_name"),
                                  expression: "errors.has('corporate_name')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [_vm._v(_vm._s(_vm.errors.first("corporate_name")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["###.###.###-##", "##.###.###/####-##"],
                                expression:
                                  "['###.###.###-##','##.###.###/####-##']",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "cpf",
                                expression: "'cpf'",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              label: "CNPJ/CPF",
                              "data-vv-as": "CNPJ/CPF",
                              name: "cnpj",
                            },
                            model: {
                              value: _vm.cac.cnpj,
                              callback: function ($$v) {
                                _vm.$set(_vm.cac, "cnpj", $$v)
                              },
                              expression: "cac.cnpj",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("cnpj"),
                                  expression: "errors.has('cnpj')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [_vm._v(_vm._s(_vm.errors.first("cnpj")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                        [
                          _c("vs-input", {
                            staticClass: "w-full mt-0",
                            attrs: { label: "IE" },
                            model: {
                              value: _vm.cac.ie,
                              callback: function ($$v) {
                                _vm.$set(_vm.cac, "ie", $$v)
                              },
                              expression: "cac.ie",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex items-end mt-2" },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-2",
                          attrs: { icon: "MapPinIcon", svgClasses: "w-5 h-5" },
                        }),
                        _c(
                          "span",
                          { staticClass: "leading-none font-medium" },
                          [_vm._v("Endereço")]
                        ),
                      ],
                      1
                    ),
                    _c("hr"),
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["#####-###"],
                                expression: "['#####-###']",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: { label: "CEP", placeholder: "CEP" },
                            on: { keyup: _vm.searchZipCode },
                            model: {
                              value: _vm.cac.address.zip_code,
                              callback: function ($$v) {
                                _vm.$set(_vm.cac.address, "zip_code", $$v)
                              },
                              expression: "cac.address.zip_code",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v("Logradouro"),
                          ]),
                          _c("v-select", {
                            attrs: {
                              reduce: (option) => option.value,
                              clearable: "",
                              options: _vm.logradouroOptions,
                              placeholder: "Selecione",
                              dir: _vm.$vs.rtl ? "rtl" : "ltr",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "no-options",
                                fn: function ({}) {
                                  return [
                                    _vm._v(
                                      "\n                    Desculpe, nenhum resultado encontrado.\n                  "
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.cac.address.logradouro,
                              callback: function ($$v) {
                                _vm.$set(_vm.cac.address, "logradouro", $$v)
                              },
                              expression: "cac.address.logradouro",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: { label: "Endereço" },
                            model: {
                              value: _vm.cac.address.street,
                              callback: function ($$v) {
                                _vm.$set(_vm.cac.address, "street", $$v)
                              },
                              expression: "cac.address.street",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              label: "Número",
                              type: "number",
                              placeholder: "S/N",
                            },
                            model: {
                              value: _vm.cac.address.number,
                              callback: function ($$v) {
                                _vm.$set(_vm.cac.address, "number", $$v)
                              },
                              expression: "cac.address.number",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-2",
                  attrs: { label: "Complemento" },
                  model: {
                    value: _vm.cac.address.complement,
                    callback: function ($$v) {
                      _vm.$set(_vm.cac.address, "complement", $$v)
                    },
                    expression: "cac.address.complement",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-2",
                  attrs: { label: "Bairro" },
                  model: {
                    value: _vm.cac.address.neighborhood,
                    callback: function ($$v) {
                      _vm.$set(_vm.cac.address, "neighborhood", $$v)
                    },
                    expression: "cac.address.neighborhood",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4_ w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Cidade"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    placeholder: _vm.cityOptions.length
                      ? "Selecione"
                      : "Selecione um estado",
                    options: _vm.cityOptions,
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.cac.address.city_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.cac.address, "city_id", $$v)
                    },
                    expression: "cac.address.city_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Estado"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    options: _vm.states,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  on: {
                    input: function ($event) {
                      _vm.fillCity(_vm.cac.address.state_id),
                        (_vm.cac.address.city_id = null)
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.cac.address.state_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.cac.address, "state_id", $$v)
                    },
                    expression: "cac.address.state_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("País"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: _vm.countryOptions,
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.cac.address.country,
                    callback: function ($$v) {
                      _vm.$set(_vm.cac.address, "country", $$v)
                    },
                    expression: "cac.address.country",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "PhoneIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Contato"),
              ]),
            ],
            1
          ),
          _c("hr"),
          _c(
            "div",
            { staticClass: "vx-row" },
            [
              _vm._l(_vm.cac.phones, function (phone, i) {
                return _c(
                  "div",
                  { key: i, staticClass: "vx-col md:w-1/5 w-full" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["(##)#####-####", "(##)####-####"],
                          expression: "['(##)#####-####','(##)####-####']",
                        },
                      ],
                      staticClass: "w-full mt-2",
                      attrs: {
                        label:
                          phone.type === "phone"
                            ? "Telefone"
                            : phone.type === "mobile"
                            ? "Celular"
                            : phone.type === "other"
                            ? "Outro"
                            : "",
                      },
                      model: {
                        value: phone.phone,
                        callback: function ($$v) {
                          _vm.$set(phone, "phone", $$v)
                        },
                        expression: "phone.phone",
                      },
                    }),
                  ],
                  1
                )
              }),
              _c(
                "div",
                { staticClass: "vx-col md:w-2/5 w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.initial",
                        value: { email: true },
                        expression: "{ email: true }",
                        modifiers: { initial: true },
                      },
                    ],
                    staticClass: "w-full mt-2",
                    attrs: {
                      label: "E-mail",
                      "data-vv-as": "E-mail",
                      type: "email",
                      name: "email",
                    },
                    model: {
                      value: _vm.cac.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.cac, "email", $$v)
                      },
                      expression: "cac.email",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("email"),
                          expression: "errors.has('email')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("email")))]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-full w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-2",
                  attrs: { label: "Observações" },
                  model: {
                    value: _vm.cac.observation,
                    callback: function ($$v) {
                      _vm.$set(_vm.cac, "observation", $$v)
                    },
                    expression: "cac.observation",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "InfoIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Médicos"),
              ]),
            ],
            1
          ),
          _c("hr"),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-full w-full mt-2" },
              [
                _c("v-select", {
                  staticClass: "vue_select_drop_size_200",
                  attrs: {
                    multiple: "",
                    clearable: "",
                    reduce: (option) => option.value,
                    options: _vm.doctors,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.cac.doctors,
                    callback: function ($$v) {
                      _vm.$set(_vm.cac, "doctors", $$v)
                    },
                    expression: "cac.doctors",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto mt-2",
                      attrs: { disabled: !_vm.validateForm },
                      on: { click: _vm.save },
                    },
                    [_vm._v("Cadastrar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4 mt-2",
                      attrs: { type: "border", color: "secondary" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("Cancelar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }